import withRoot from "../utils/withRoot";
import React from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import withStyles from "@material-ui/styles/withStyles";
import { fade } from "@material-ui/core/styles";
import SearchBox from "../components/SearchBox";
import { StaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { MaterialUi } from "mdi-material-ui";
import Logo from "../components/Logo";
import SEO from "../components/SEO";
import Page from "../components/Page";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import { makeStyles } from "@material-ui/core/styles";
import Highlighter from "react-highlight-words";
import Slider from "@material-ui/core/Slider";

import Divider from "@material-ui/core/Divider";
import SearchBar from "material-ui-search-bar";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import qs from "qs";

const useStyles = makeStyles(theme => ({
  listroot: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
  },
  listinline: {
    display: "inline",
    // width: 300,
  },
  highlighter: {
    width: "auto",
  },
}));

class Tiwen extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      age: 1,
      content: "",
      submitted: false,
      form: {},
      inputDisable: false,
      status: "",
    };
  }

  componentDidMount = () => {
    const { location } = this.props;
    console.log(location);
    let params = qs.parse(location.search);

    if (params["?code"] !== undefined) {
      let code = params["?code"];
      console.log(code);
      fetch("https://kids-abc.cn/msg/openid?code=" + code)
        .then(resp => resp.json())
        .then(resp => {
          console.log(resp);
          this.setState({
            form: resp,
          });
        })
        .catch(e => {
          console.log(e);
          console.log("获取access token失败");
        });
    } else {
      this.setState({
        age: 1,
        content: "请从微信公众号点开此链接",
        inputDisable: false,
      });
    }
  };

  handleTiwen = () => {
    let { form, age, content } = this.state;
    form["age"] = age;
    form["content"] = content;
    console.log(form);
    fetch("https://kids-abc.cn/msg/api/faqs", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      body: JSON.stringify(form),
      headers: new Headers({ "content-type": "application/json" }),
    })
      .then(resp => {
        if (resp.ok) {
          console.log(resp.status, resp.statusText);
          resp.json();
        } else {
          return Promise.reject({ error: "请求失败" });
        }
      })
      .then(resp => {
        console.log(resp);
        this.setState({
          status: "提交成功",
          age: 1,
          content: "",
        });
      })
      .catch(error => {
        this.setState({
          status: "提交失败",
          age: 1,
          content: "",
        });
        console.log(error);
        console.log("发送失败!");
      });
  };

  valuetext = value => {
    return value + "岁";
  };

  render() {
    const { classes } = this.props;
    const marks = [
      {
        value: 0,
        label: "0-1岁",
      },
      {
        value: 1,
        label: "1-2岁",
      },
      {
        value: 2,
        label: "2-3岁",
      },
      {
        value: 3,
        label: "3-4岁",
      },
      {
        value: 4,
        label: "4-5岁",
      },
      {
        value: 5,
        label: "5-6岁",
      },
      {
        value: 6,
        label: "6岁以上",
      },
    ];
    return (
      <Page title="我要提问">
        <SEO title="提问">
          <meta content="我要提问" name="description" />
        </SEO>
        <Typography id="discrete-slider" gutterBottom>
          宝宝年龄
        </Typography>
        <Slider
          defaultValue={1}
          getAriaValueText={this.valuetext}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="on"
          step={1}
          value={this.state.age}
          onChange={(e, age) => {
            this.setState({
              age: age,
            });
          }}
          disabled={this.state.inputDisable}
          marks={marks}
          min={0}
          max={6}
        />
        <TextField
          id="standard-full-width"
          multiline
          rows={7}
          label="问题主要内容"
          style={{ margin: 8 }}
          placeholder="输入问题"
          value={this.state.content}
          disabled={this.state.inputDisable}
          onChange={e => {
            this.setState({ content: e.target.value });
          }}
          //   helperText="输入问题"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={this.state.inputDisable}
          onClick={this.handleTiwen}
        >
          提交
        </Button>
        <Typography>{this.state.status}</Typography>
      </Page>
    );
  }
}

export default withRoot(Tiwen);
